@use '~@angular/material' as mat;
@import './shared/styles/customcolor.scss';
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker hue.
// $theme-primary: mat-palette($mat-teal);
// $theme-accent:  mat-palette($mat-light-blue, 500, 200, 700);


$theme-primary-hue: 500;
$theme-accent-hue: 500;
$theme-warn-hue: 500;

// $theme-primary: mat.define-palette(mat.$blue-palette, $theme-primary-hue);
// $theme-accent: mat.define-palette(mat.$teal-palette, $theme-accent-hue);
// $theme-warn: mat.define-palette(mat.$red-palette, $theme-warn-hue);

// custom colors
$theme-primary: mat.define-palette($md-primary, $theme-primary-hue);
$theme-accent: mat.define-palette($md-accent, $theme-accent-hue);
$theme-warn: mat.define-palette($md-warn, $theme-warn-hue);

$theme: mat.define-light-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn
  )
));

$dark-theme: mat.define-dark-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn
  )
));
@include mat.all-component-themes($theme);

.fury-default {
  @import "./shared/styles/themes/default";

  background: $theme-background;
  color: $theme-text;

  // @import "./pages/apps/inbox/inbox.component.theme";
  // @import "./pages/apps/chat/chat.component.theme";
  // @import "./pages/forms/form-wizard/form-wizard.component.theme";
  // @import "./pages/coming-soon/coming-soon.component.theme";
  @import "./layout/config-panel/config-panel.component.theme";
}

.fury-light {
  @import "./shared/styles/themes/light";

  background: $theme-background;
  color: $theme-text;

  // @import "./pages/apps/inbox/inbox.component.theme";
  // @import "./pages/apps/chat/chat.component.theme";
  // @import "./pages/forms/form-wizard/form-wizard.component.theme";
  // @import "./pages/coming-soon/coming-soon.component.theme";
  @import "./layout/config-panel/config-panel.component.theme";
}

.fury-dark {
  @include mat.all-component-colors($dark-theme);
  $theme: $dark-theme;

  @import "./shared/styles/themes/dark";

  background: $theme-background;
  color: $theme-text;

  // @import "./pages/apps/inbox/inbox.component.theme";
  // @import "./pages/apps/chat/chat.component.theme";
  // @import "./pages/forms/form-wizard/form-wizard.component.theme";
  // @import "./pages/coming-soon/coming-soon.component.theme";
  @import "./layout/config-panel/config-panel.component.theme";
}

.fury-flat {
  @import "./shared/styles/themes/flat";

  background: $theme-background;
  color: $theme-text;

  // @import "./pages/apps/inbox/inbox.component.theme";
  // @import "./pages/apps/chat/chat.component.theme";
  // @import "./pages/forms/form-wizard/form-wizard.component.theme";
  // @import "./pages/coming-soon/coming-soon.component.theme";
  @import "./layout/config-panel/config-panel.component.theme";
}
