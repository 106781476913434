/* You can add global styles to this file, and also import other style files */
@import "src/app/shared/styles/core";
@import "src/app/app.theme";

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


.cellcustom {
  color: #2196f3 !important;
  cursor: pointer;

  &:hover {
    // color: #0a568c;
    font-weight: 600;
  }

}

.logo {
  width: 250px;
}

.dashboardlogo {
  width: 150px;
}

.loading-shade {
  position: absolute;
  top: 25%;
  left: 0;
  bottom: 160px;
  right: 0;
  min-height: 30vh;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

}

@media screen and (min-width: 1600px) {
  .loading-shade {
    top: 20%;
  }
}

.scrollalign {
  top: -35px;
  position: absolute;
  left: 43%;
  height: 40vh !important;
}

.scrollalign-center{
  top: -35px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -6%);
  width: 100%;
  height: 40vh !important;
}

@media screen and (min-width: 1600px) {
  .scrollalign {
    top: -121px;
    left: 43%;
  }
}


.rate-limit-reached {
  max-width: 360px;
  text-align: center;
}

.mat-header-cell {
  font-size: 13px;
  font-weight: 550;
}

.success.mat-snack-bar-container {
  background: #4caf50;
  color: white;
}

.warn.mat-snack-bar-container {
  background: #ff9800;
  color: white;
}

.error.mat-snack-bar-container {
  background: #f44336;
  color: white;
}

.info.mat-snack-bar-container {
  background: #2196f3;
  color: white;
}

.logo-bg {
  background: whitesmoke;
}

.panelSelect {
  margin-top: 30px !important;
}

.panelSelectcsv {
  margin-top: 30px !important;
  max-height: 200px !important;
  overflow-y: auto !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 0.6em 0 !important;
}

// .mat-form-field-infix {
//   // padding: 0.5em 0;
//   border-top: 0 solid transparent !important;
// }

.full-width {
  width: 100%;
}

.fury-page-layout-header {
  height: 80px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: row;
  place-content: center flex-start;
  align-items: center;
}


::ng-deep .mat-paginator-icon {

  display: none;
}

.pagination-bg {
  background-color: whitesmoke;
}

.btn-hover {
  :hover {
    background-color: #ebebeb;
    color: #000000;
    border-radius: 100%;
    padding: 4px 0;
  }
}

.smbutton {
  font-size: 12px;
  padding: 0px 5px;
  cursor: pointer;
  background-color: #e8ebf0;
  border: 1px solid transparent;
  border-radius: 10px;
  color: #16475f;
  margin-top: 2px;
  height: 19px;

  &:hover {
    background-color: #b9c2d1;
    border: 1px solid rgb(47, 46, 46);
  }
}

.p-b-0 .mat-form-field-wrapper {
  padding-bottom: 0;
}

.form-field-label-wrapper {
  padding-bottom: 1.34375em;
}

.mat-tree-node {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  height: 48px;
  text-align: left;
  text-decoration: none;
  max-width: 100%;
  position: relative;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  box-sizing: border-box;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}

.csv-icon {
  width: 24px;
  height: 24px;
  background-image: url('assets/icons/csv.png');
  background-size: cover; 
}

.pdf-icon {
  width: 24px;
  height: 24px;
  background-image: url('assets/icons/pdf.png');
  background-size: cover; 
}

.jpg-icon {
  width: 24px;
  height: 24px;
  background-image: url('assets/icons/jpg.png');
  background-size: cover; 
}

.jpeg-icon {
  width: 24px;
  height: 24px;
  background-image: url('assets/icons/jpeg.png');
  background-size: cover; 
}

.png-icon {
  width: 24px;
  height: 24px;
  background-image: url('assets/icons/png.png');
  background-size: cover; 
}

.doc-icon {
  width: 24px;
  height: 24px;
  background-image: url('assets/icons/doc-icon.svg');
  background-size: cover; 
}

.xlsx-icon {
  width: 24px;
  height: 24px;
  background-image: url('assets/icons/xlsx-icon.png');
  background-size: cover; 
}

form.dashboard-range-picker .mat-form-field-appearance-legacy .mat-form-field-prefix .mat-datepicker-toggle-default-icon, .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon{
  width: 2em !important;
}