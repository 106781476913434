.sidenav-item {
  color: $sidenav-item-color;

  .subheading {
    color: $sidenav-item-subheading-color;
    font-family: $sidenav-item-subheading-typography-font-family;
  }

  .sidenav-item-link {
    color: inherit;
    font-family: $sidenav-item-typography-font-family;
    font-size: $sidenav-item-typography-font-size;
    font-weight: $sidenav-item-typography-font-weight;

    &:hover {
      background: $sidenav-item-background-hover;
      color: $sidenav-item-color-hover;

      .text-icon {
        color: inherit;
      }
    }

    &.active {
      background: $sidenav-item-background-active;
      color: $sidenav-item-color-active;

      .text-icon {
        color: inherit;
      }
    }

    .text-icon {
      color: $sidenav-item-text-icon-color;
    }
  }

  &.level-0 {
    &.dropdown-open {
      background: $sidenav-item-dropdown-level1;

      > .sidenav-item-link {
        color: $sidenav-item-color-hover;
      }
    }
  }

  &.level-1 {
    background: $sidenav-item-dropdown-level1;

    .sidenav-item-link {
      // padding-left: $sidenav-item-padding-left-level1;
      // padding-left:$sidenav-item-height
      padding-left: 34px;
    }
  }

  &.level-2 {
    background: $sidenav-item-dropdown-level2;

    .sidenav-item-link {
      // padding-left: $sidenav-item-padding-left-level2;
      padding-left: 45px !important;
    }
  }

  &.level-3 {
    background: $sidenav-item-dropdown-level3;

    .sidenav-item-link {
      // padding-left: $sidenav-item-padding-left-level3;
      padding-left: 58px !important;
    }
  }

  &.level-4 {
    background: $sidenav-item-dropdown-level4;

    .sidenav-item-link {
      // padding-left: $sidenav-item-padding-left-level4;
      padding-left: 68px !important;
    }
  }
  // &.level-5 {
  //   background: $sidenav-item-dropdown-level5;

  //   .sidenav-item-link {
  //     padding-left: $sidenav-item-padding-left-level5;
  //     padding-left: 788px !important;
  //   }
  // }
}
