/* For use in src/lib/core/theming/_palette.scss */

$md-primary: (
    50 : #e0e8ef,
    100 : #b3c5d8,
    200 : #809fbe,
    300 : #4d79a4,
    400 : #265c91,
    500 : #003f7d,
    600 : #003975,
    700 : #00316a,
    800 : #002960,
    900 : #001b4d,
    A100 : #809fff,
    A200 : #4d79ff,
    A400 : #1a53ff,
    A700 : #013fff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-accent: (
    50 : #e5f9e0,
    100 : #c8e7b3,
    200 : #a7d380,
    300 : #86c04d,
    400 : #66b326,
    500 : #45a500,
    600 : #3f9e00,
    700 : #369300,
    800 : #2c8800,
    900 : #1f7700,
    A100 : #ffffff,
    A200 : #ebf7f2,
    A400 : #c0e0bf,
    A700 : #a1d8a6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-warn: (
    50 : #ffeae1,
    100 : #ffcbb3,
    200 : #ffa881,
    300 : #ff854f,
    400 : #ff6a29,
    500 : #ff5003,
    600 : #ff4903,
    700 : #ff4002,
    800 : #ff3702,
    900 : #ff2701,
    A100 : #ffffff,
    A200 : #fff3f2,
    A400 : #ffc6bf,
    A700 : #ffaea6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);